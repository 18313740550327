<!-- 成绩复核列表 -->
<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getCertificateList,checkApply } from "@/api/grade";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "证书列表",
      items: [
        {
          text: "证书邮寄",
        },
        {
          text: "证书列表",
          active: true,
        },
      ],
      defaultvalue: 3,
      currentpage: 3,
      certificateList: [],
      checkList: [],
      nameList: [],
    };
  },
  watch: {
    checkList: {
      handler(newValue,oldValue) {
        let list = [];
        this.checkList.forEach((v) => {
          let data = this.certificateList.filter((x) => {
            return x.zsid == v;
          })[0];
          list.push(data.zsmc);
        });
        this.nameList = list;
        if(newValue.length>0){
         let zskid =  newValue.find(x=>oldValue.indexOf(x)===-1);
         if(zskid){
           this.hasApply(zskid)
         }
        }
      },
    },
  },
  methods: {
    send() {
      if(this.checkList.length == 0){
        this.$message('请选择需要邮寄的证书');
        return
      }
      let data = this.checkList.join(",");
      window.sessionStorage.setItem("name", this.nameList.join(","));
      this.$router.push({
        path: "./scoreSend",
        query: {
          zsid: data,
        },
      });
    },
    hasApply(zskid){
      checkApply(zskid).then((res)=>{
        if (res.status) {
          if (res.data) {
            this.$message.warning('这个证书已经申请邮寄过了，不能再次申请邮寄');
            this.certificateList.forEach(x=>{
              if(x.zskid===zskid){
                x.yxyj = false;
              }
            })
            this.checkList.splice(this.checkList.findIndex(x=>x===zskid),1);
          }
        }
      });
    }
  },
  created() {
    getCertificateList().then((res) => {
      if (res.status) {
        let data = res.data||[];
        //筛选省直考区
        let filter = data.filter(x=>x.yxyj==1);
        this.certificateList = filter;
      }
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4
              class="card-title pb-3 border-dash flexList d-flex justify-content-between"
            >
              <span class="mr-2">邮寄证书列表(仅限报名地为“四川省直属考区”的考生)</span>
              <button type="button" class="btn btn-info h30" @click="send">
                邮寄
              </button>
            </h4>
            <div class="table-responsive ">
              <el-checkbox-group v-model="checkList">
                <table
                  class="table table-has-bg light-table table-hover table-bordered"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 4%">选择</th>
                      <th>证书名称</th>
                      <th>证书编号</th>
                      <th style="width: 15%">专业通过</th>
                      <th style="width: 15%">报考级别</th>
                      <th style="width: 15%">报考专业</th>
                      <th style="width: 5%">允许邮寄</th>
                      <th style="width: 5%">领取方式</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in certificateList" :key="index">
                      <th scope="row">
                        <el-checkbox
                          :label="item.zsid"
                          :disabled="!item.yxyj"
                        >{{''}}</el-checkbox>
                      </th>
                      <td>{{ item.zsmc }}</td>
                      <td>{{ item.zsglh }}</td>
                      <td>{{ item.zytg }}</td>
                      <td>{{ item.bkjbmc }}</td>
                      <td>{{ item.bkzymc }}</td>
                      <td>{{ item.yxyj ? "是" : "否" }}</td>
                      <td>{{ item.lqfs===1 ? "邮寄" :item.lqfs===2 ? "自领取" : "" }}</td>
                    </tr>
                  </tbody>
                </table>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
